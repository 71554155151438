<template>
  <el-card id="sip">
    <div class="IncomingCallBox">
      666
    </div>
    <header>
      <h1>SIP呼叫</h1>
    </header>
    <hr />
    <main>
      <div class="btns">
        <el-input type="text" placeholder="输入号码" v-model="phoneNum" size="mini" @input="setPhone()"
          style="width: 160px; margin-right: 10px"></el-input>
        <!-- <el-button type="primary" size="mini" @click="getSipInfo"
          >获取SIP信息</el-button
        >
        
        <el-button type="primary" size="mini" @click="callPhone">
          连接SIP</el-button
        > -->
        <el-button type="success" size="mini" @click="stopCall()">登出</el-button>
        <el-button type="success" size="mini" @click="toRegister()">注册</el-button>
        <el-button type="success" size="mini" @click="testCall()">拨打号码</el-button>


        <el-tooltip v-if="cutCall == true" class="item" effect="dark" content="拨打前确保麦克风、扬声器、耳机设备没有问题,否则可能会通话没有声音的情况"
          placement="bottom">
          <el-button type="success" size="mini" @click="call()">SIP拨号</el-button>
        </el-tooltip>
        <el-button type="danger" size="mini" @click="hangup()" v-else>挂断</el-button>
      </div>
      <!-- //挂断、接通、执行的录音 -->
      <audio id="audio" autoplay="autoplay" src="" controls></audio>
      <audio id="audioB" src="../../assets/mp3/nocall.wav" controls style="display: none"></audio>
    </main>
  </el-card>
</template>

<script>
// import { confirmUserOnline, selectDzwlUserApi } from "@/api/dzwl";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { callAppBind } from "@/api/call";
export default {
  data() {
    return {
      disabled: true,
      cutCall: true,
      ua: null,
      socket: null,
      eventHandlers: null,
      phoneNum: null,
      config: {},
      isRegister: false, // 是否已注册
      registerState: false,
    };
  },
  mounted() {
    // 2、复制给window对象
    window.logStr = this.logStr;
  },
  methods: {
    stopCall() {
      this.ua.stop();
    },
    setPhone() {
      // console.log("一阶段", this.phoneNum);
      this.phoneNum = this.phoneNum.replace(/[^\d]/g, "");
      // console.log("二阶段", this.phoneNum);
      this.phoneNum = this.phoneNum.slice(0, 12);
      // console.log("三阶段", this.phoneNum);

      if (this.phoneNum.replace(/[^\d]/g) && this.phoneNum.length == 11) {
        this.disabled = false;
      }
    },
    // 挂断
    hangup() {
      this.cutCall = true;
      if (this.ua) {
        console.log("挂断了");
        // this.ua.stop(); // 断开链接/注销
        this.ua.terminateSessions(); // 挂断电话
      }
      let audio = document.getElementById("audio");
      audio.src = "";
      audio.play();
      audio.pause();
      let audioB = document.getElementById("audioB");
      audioB.pause();
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 注册事件
    toRegister() {
      // 如果UA已注册，返回true; 否则返回false。
      let bool = this.ua.isRegistered()
      if (bool) {
        console.log('已注册');
      } else {
        console.log('未注册');
        this.ua.register();
      }
    },
    // 拨号
    call() {
      // if (!this.phoneNum) {
      //   console.log("号码不能为空");
      //   Message.warning("号码不能为空");
      //   return false;
      // } else if (this.phoneNum.length < 11) {
      //   console.log("号码必须为11位");
      //   Message.warning("手机号码必须为11位");
      //   return false;
      // }
      this.cutCall = false;

      // 需要确认有无这些文件导入 (selectDzwlUserApi和confirmUserOnline接口,Message插件)
      //根据用户IP 返回城市级别的定位结果
      // let native = new BMap.LocalCity();
      // native.get(async (res) => {
      //   let params = {
      //     companyId: localStorage.getItem("parentId"),
      //     userId: localStorage.getItem("id"),
      //   };
      //   // 确认在不在白名单
      //   let res1 = await selectDzwlUserApi(params);
      //   let data1 = res1.data.data;
      //   if (data1.length != 0) {
      // 可以拨打的地方
      this.$nextTick(async () => {
        Message.success("sip1拨打开始");
        await this.getSipInfo();
        // console.log("获取接口");

        if (!this.registerState) {
          this.callPhone(); // 注册
          console.log("注册事件");
        }
        // await this.testCall(); // 打电话
        console.log("判断是否注册");
      });
      //   } else {
      //     // 确定用户在不在经纬度接口
      //     params = {
      //       userLongitude: res.center.lng, //围栏圆心经度
      //       userLatitude: res.center.lat, ////围栏圆心纬度
      //       companyId: localStorage.getItem("parentId"), // 公司id
      //       userId: localStorage.getItem("id"), // 用户id
      //     };
      //     let res2 = await confirmUserOnline(params);
      //     if (res2.data.statusCode == "00000") {
      //       // 可以拨打的地方
      // this.$nextTick(async () => {
      //   await this.getSipInfo();
      //   console.log("获取接口");
      //   if (!this.registerState) {
      //     this.callPhone(); // 注册
      //     console.log("注册事件");
      //   }
      //   await this.testCall();
      //   console.log("判断是否注册");
      // });
      //     } else {
      //       Message.warning("你当前的位置不在公司围栏内!");
      //       return false;
      //     }
      //   }
      // });
    },
    logStr() {
      console.log("你好");
    },
    testCall(sip_phone_number_) {
      // this.$notify({
      //   title: '来电提示',
      //   duration: 0,
      //   dangerouslyUseHTMLString: true,
      //   showClose: false,
      //   message: `<p onclick="logStr()">我是模板字符串，点击我。</p>`
      // });
      // return false
      console.log("sip3");
      // Register callbacks to desired call events
      // 判断是否注册
      if (this.isRegister) {
      }
      let options = {
        eventHandlers: this.eventHandlers,
        mediaConstraints: {
          audio: true,
          video: false,
          mandatory: { maxWidth: 640, maxHeight: 360 },
        },
      };

      // let obj = {
      //   sip: "47.98.172.37:5960",
      //   wsip: "wss://h5.zzz888.cn:7443",
      //   username: "26ac631ac14213882738650",
      //   password: "28ca40e86b27c4a8f1b606587eec80f6",
      //   uri: `sip:26ac631ac14215171505290@47.98.172.37:5960`,
      // };

      console.log("this.config", this.config);
      let obj = {
        sip: this.config.sip,
        wsip: this.config.wssip,
        username: this.config.ws_user,
        password: this.config.ws_pass,
        uri: `sip:${this.config.ws_user}@${this.config.sip}`,
      };

      // let obj = {
      //   sip: "cc.noai.cc:2333",
      //   wsip: "wss://cc.noai.cc:2333",
      //   username: "13882738650",
      //   password: "MlxAUwUDAgMC0e460b884da0773fa3544264",
      //   uri: `sip:wss://cc.noai.cc:2333@cc.noai.cc:2333`,
      // };

      this.ua.call("sip:fs" + this.phoneNum + "@" + obj["sip"], options);
    },
    //sip 配置
    getUa() { },
    // 调用接口，获取sip信息
    async getSipInfo(phone) {
      console.log("sip11111111");
      // this.phoneNum = "13882738650";
      // 获取主叫号码
      let activePhone = localStorage.getItem("ms_username");
      let parentId = localStorage.getItem("parentId");
      // let params = {
      //   activePhone: activePhone,
      //   passivePhone: this.phoneNum,
      //   parentId,
      // };

      // let params = {
      //   "activePhone": "13882738651",
      //   "passivePhone": "13882738650",
      //   "appid": "69894cf97a6646af9e3ec086913d54a9",
      //   "ip": "118.190.199.147"
      // }
      let res = await callAppBind(params);
      if (res.data.statusCode == "00000") {
        Message.success("sip拨打开始");
      } else if (res.data.statusCode != "00000") {
        this.hangup();
        Message.error(res.data.message);
        return false;
      } else {
        this.hangup();
        Message.error(res.data.data);
        return false;
      }
      this.config = res.data.data;

      // 常用参数
      // this.config = {
      //   // "wsip": "wss://183.232.157.234:2233",
      //   "ws_pass": "MlxAUwUDAgMC0e460b884da0773fa3544264",
      //   "ws_user": "13882738650",
      //   "sip": "183.232.157.234:9060",
      //   "wssip": "wss://cc.noai.cc:2333",
      //   // "taskid": "ld-acd932398716a31748c4fd7216322f34"
      // }

      // this.config = {
      //   "ws_pass": "19008",
      //   "ws_user": "19008",
      //   "sip": "183.232.157.231:9060",
      //   "wssip": "wss://cc.noai.cc:2333",
      // }


      // this.config = {
      //   "wsip": "wss://183.232.157.231:2233",
      //   "ws_pass": "19008",
      //   "ws_user": "19008",
      //   "sip": "183.232.157.231:9060",
      //   "wssip": "wss://183.232.157.231:2333",
      // }

      // axios.post("http://118.190.211.245:9006/test/sip", params).then((res) => {
      //   this.config = res.data.data;
      //   //请求的数据存储在res.data 中
      //   console.log("res的值", res.data);
      // });
    },
    // 注册sip
    callPhone() {
      console.log("sip2");
      console.log("registerState", this.registerState);
      // debugger;

      // 拨打号码
      // this.getSipInfo()
      JsSIP.C.SESSION_EXPIRES = 120;
      JsSIP.C.MIN_SESSION_EXPIRES = 120;
      let obj = {
        sip: this.config.sip,
        wsip: this.config.wssip,
        username: this.config.ws_user,
        password: this.config.ws_pass,
        uri: `sip:${this.config.ws_user}@${this.config.sip}`,
      };

      // let obj = {
      //   sip: "47.98.172.37:5960",
      //   wsip: "wss://h5.zzz888.cn:7443",
      //   username: "26ac631ac14213882738650",
      //   password: "28ca40e86b27c4a8f1b606587eec80f6",
      //   uri: `sip:26ac631ac14215171505290@47.98.172.37:5960`,
      // };

      // let obj = {
      //   sip: "183.232.157.234:9060",
      //   // wsip: "ws://183.232.157.234:2233",
      //   wsip: "wss://cc.noai.cc:2333",
      //   username: "13882738650",
      //   password: "NlIfUwdaCQFWAA0CBAA=e7a552fad1785c2d2b361960",
      //   uri: `sip:13882738650@183.232.157.234:9060`,
      // };

      this.eventHandlers = {
        progress: function (e) {
          Message.success("开始呼叫");
          console.log("call is in progress");
        },
        failed: function (e) {
          console.log("call failed: ", e);
        },
        ended: function (e) {
          console.log("call ended : ", e);
        },
        confirmed: function (e) {
          console.log("call confirmed");
        },
      };
      // 配置信息
      if (this.ua) {
        this.ua.stop();
      }
      this.socket = new JsSIP.WebSocketInterface(obj.wsip);
      // const socket = new this.JsSIP.WebSocketInterface(obj.wsip);
      const configuration = {
        sockets: [this.socket],
        uri: obj.uri,
        password: obj.password,
        register: false, //指示JsSIP用户代理是否应在启动时自动注册
        //utbound_proxy_set: obj.wsip,
        contact_uri:
          "sip:" +
          obj["username"] +
          "@" +
          obj["sip"] +
          ";transport=" +
          (obj["wsip"].substr(0, 3) == "wss" ? "wss" : "ws"),
      };
      console.log("configuration", configuration);
      // 创建UA
      let options = {
        eventHandlers: this.eventHandlers,
        mediaConstraints: { audio: true, video: false },
      };

      this.ua = new JsSIP.UA(configuration);
      // console.log("call号码", "sip:bob" + "18398754423" + "@" + obj["sip"]);
      // this.ua.call("sip:fs" + "17381586338" + "@" + obj["sip"], options);
      // 状态回调
      this.ua.on("connected", function (e) {
        console.log("--------已连接---------", e);
        console.log("注册状态(已连接)", this.registerState);

      });
      this.ua.on("disconnected", function (e) {
        Message.success("通话已结束");
        console.log("-------未连接--------", e);
      });
      this.ua.on("registered", function (e) {
        console.log("--------已注册-------", e);
        // this.isRegister = true;
        // this.ua.unregister((options = null));
        this.registerState = true;
        console.log("注册状态(已注册)", this.registerState);
      });
      this.ua.on("unregistered", function (e) {
        console.log("------未注册--------", e);
        // this.isRegister = false;
        // this.ua.register();
        this.registerState = false;
      });
      this.ua.on("registrationFailed", (e) => {
        // this.$message.error("SIP注册失败,请联系管理员");
      });
      //客户接到了电话才会走这里
      this.ua.on("newRTCSession", (e) => {
        console.log("newRTCSession", e);

        // if (response.originator == 'remote') {
        //     consloe.log('被叫接听')
        //   }

        //   if (response.originator == 'local') {
        //     consloe.log('主叫接听')
        //   }

        //   // 远程发起的电话/ 应答传入的会话。此方法仅适用于传入会话。
        //   session.answer({
        //     mediaConstraints: { audio: true }
        //   })

        let audio = document.getElementById("audio");
        let session = e.session;
        // 闪亮登场的最终方式
        // const stream = new MediaStream();
        // const receivers = session.connection?.getReceivers();
        // if (receivers) receivers.forEach((receiver) => stream.addTrack(receiver.track));
        // audio.srcObject = stream;
        // 最后都要播放

        // this.nextCallIntervalTimerEvent = e;
        let peerconnection = session.connection;
        if (e.originator === "local") {
          // 拨打呼出
          // peerconnection.addEventListener("addstream", (event) => {
          //   // try {
          //     audio.srcObject = event.stream;
          //     console.log("打电话", event.stream);
          //   // } catch (e) {
          //     // console.log(e);
          //   // }
          // });

          // 获取音频流
          // peerconnection.ontrack = (event) => {
          //   audio.srcObject = event.streams[0];
          //   console.log("打电话", event.stream);
          // };
          //    let audio = $('#audio')[0];
          // audio.srcObject=session.connection.getRemoteStreams()[0];
        } else if (e.originator === "remote") {
          // 被叫呼入
          //回答传入会话。此方法仅适用于传入会话。
          //answer设置的自动接听
          //RTCSession 的 answer 方法做了自动接听。实际开发中，你需要弹出一个提示框，让用户选择是否接听
          session.answer({
            'mediaConstraints': { 'audio': true, 'video': false },
            // 'mediaStream': localStream
          });
          //拿到远程的音频流
          // peerconnection.addEventListener("addstream", function (ev) {
          //   console.info('onaddstream from remote - ', ev.stream);
          //   audio.srcObject = ev.stream;
          // });
        } else {
          let callers = session.remote_identity.uri.user;
          //emitter.setCallinStatus.call(true, callers);
        }

        // 接听失败
        session.on("failed", (mdata) => {
          Message.success("电话已挂断");
          //emitter.setCallinStatus.call(false);
          // console.log("来电的时候 拒接或者 还没接听对方自己就挂断了");
        });




        // 接听成功
        session.on("accepted", (response, cause) => {
          console.log("接听成功");
          Message.success("接听成功");
          let res = response || "参数异常"
          console.log("接口返回的参数", res);

          // let audio = document.getElementById("audio");
          // const stream = new MediaStream();
          // const receivers = session.connection?.getReceivers();
          // if (receivers)
          //   receivers.forEach((receiver) => stream.addTrack(receiver.track));
          // audio.srcObject = stream;
          // // 最后都要播放
          // audio.play();

          //emitter.setCallinStatus.call(false);
        });

        // 接听成功后 挂断
        session.on("ended", () => {
          console.log("接听结束");
          Message.success("对方已挂断");
        });

        // 通话被挂起
        session.on("hold", (data) => {
          let org = data.originator;
          if (org === "local") {
            // console.log("通话被本地挂起:", org);
          } else {
            // console.log("通话被远程挂起:", org);
          }
        });

        // 通话被继续
        session.on("unhold", (data) => {
          let org = data.originator;
          if (org === "local") {
            console.log("通话被本地继续:", org);
          } else {
            console.log("通话被远程继续:", org);
          }
        });
        // console.log(
        //   "Callid=" +
        //     e.request.headers["X-Callid"][0].raw +
        //     "------------------Phone=" +
        //     e.request.headers["X-Phone"][0].raw
        // );
        // const Taskid = e.request.headers["X-Taskid"][0].raw;
        // const Phone = e.request.headers["X-Phone"][0].raw;
        // const callId = e.request.headers["X-Callid"][0].raw;

        e.session.on("confirmed", (e) => {
          console.log("--------------------------电话接通，开始通话");
          Message.success("电话已接通，开始通话");
        });
        let _this = this;
        e.session.on("ended", (e) => {
          let audioB = document.getElementById("audioB");
          audioB.loop = true;
          audioB.play();
          setTimeout(() => {
            audioB.pause();
          }, 10000);
          Message.success("电话已挂断");
          console.log("---------------------------电话已挂断，开始问卷");
        });
        //绑定回调后先给坐席‘滴’一声
        // e.session.answer();

        // 能拿到远程的音频流
        e.session.connection.addEventListener("addstream", function (ev) {
          console.info('onaddstream from remote - ', ev.stream);
          audio.srcObject = ev.stream;
        })
        // peerconnection.ontrack = (event) => {
        //   console.log("打电话", event.stream);
        //   audio.srcObject = event.streams[0];
        // };
      });
      // 登陆
      this.ua.start();
      // 登出
      // ua.stop();
    },
  },
  // mod卸载前
  beforeMount() {
    console.log("mod卸载前");
  },
};
</script>

<style lang="less" scoped>
// /deep/.el-card__body {
//   padding: 20px 10px;
//   box-sizing: border-box;
// }
#sip {
  header {
    h1 {
      font-weight: normal;
    }
  }

  main {
    .btns {
      display: flex;
      margin-bottom: 20px;
    }
  }
}
</style>
